<template>
    <v-container fluid>
        <v-card flat :loading = loading>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-row class="flex-nowrap">
                            <v-col cols="1" style="max-width: 80px">
                                <v-btn @click="setBack()"  class="back-btn" link large>
                                    <v-icon>mdi-less-than</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="11">
                                <v-subheader class="headline">{{ $t('orders') }}</v-subheader>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-list three-line>
                            <template v-for="(item, index) in notificationItems">
                                <v-list-item
                                    :key="item.uuid"
                                    class="cursor-pointer"
                                    @click="notificationClick(item)"
                                >
                                    <v-list-item-avatar>
                                        <v-icon
                                            :class="(item.read ? 'grey' : 'primary')"
                                            class=" lighten-1"
                                            dark
                                        >
                                            mdi-card-text
                                        </v-icon>
                                          </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.title"></v-list-item-title>
                                        <v-list-item-subtitle v-html="item.message"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-list-item-action-text v-text="item.date"></v-list-item-action-text>
                                        <v-icon color=" lighten-1"
                                                :color="(item.read ? 'grey' : 'primary')"
                                        >
                                            mdi-arrow-right-bold
                                        </v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider
                                    :key="index"
                                    inset
                                ></v-divider>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-show="totalNotifications > perPage" v-model="page" :total-visible="totalVisiblePag"
                              @input="getNotifications"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
        <v-dialog
            v-model="dialogFileError"
            width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t('errors_found_uploaded_file') }}</span>
                </v-card-title>
                <v-card-text v-for="(error, i) in textFileError" :key="i" v-html="error">

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogFileError = false"
                    >
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
   </v-container>


</template>
<script>
    import {mapGetters} from "vuex"
    export default {
        name: 'Notifications',
        data() {
            return {
                tab: 0,
                loading: false,
                language: null,
                page: 1,
                pageCount: 0,
                perPage: 10,
                totalVisiblePag: 5,
                totalNotifications: 0,
                notificationItems: [],
                textFileError: [],
                dialogFileError: false,
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },
        },
        watch: {
            "pagination.page": (newPage) => {
                this.getNotifications(newPage);
            }
        },
        async mounted() {
            this.language = this.languages[this.tab]
            await this.getNotifications()
        },
        methods: {
            setBack(){
                window.history.back();
            },
            async getNotifications(page) {
                this.loading = true
                let params = {}
                if (page !== undefined) {
                    params.page = page;
                }
                if (this.perPage !== undefined) {
                    params.perPage = this.perPage;
                }

                await this.$http
                    .get("partner/notification", {
                        params: params,
                    })
                    .then(res => {
                        this.notificationItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalNotifications = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                        this.loading = false
                    })
                    .catch(err => {
                        this.notificationItems = []
                        this.totalNotifications = 0
                        this.$toastr.error(this.$t('failed_to_get_notifications'))
                        this.loading = false
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            notificationClick(item){
                if(item.read === 0){
                    this.notificationRead(item)
                }
                if(item.data && item.data.type && item.data.type === 'file_xml' && item.data.url){
                    window.open(item.data.url);
                }
                else if(item.data && item.data.type && item.data.type === 'error_xml_file' && item.data.errors){
                    this.textFileError = item.data.errors
                    this.dialogFileError = true
                }
            },
            async notificationRead(item){
                this.loading = true
                var formData = new FormData()
                await this.$http
                    .put(`partner/notification/${item.uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    })
                    .then(res => {
                        item.read = 1;
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('notify_has_not_been_updated'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        }
    }
</script>
